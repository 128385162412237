<template>
  <div class="stack-item">
    {{ title }}
  </div>
</template>

<script>
import "./StackItemComponent.scss";

export default {
  name: 'StackItemComponent',

  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>