<template>
    <a class="text-link" :href="link" target="_blank">
        {{ text }}
        <font-awesome-icon class="link-icon" :icon="['fa', 'angle-right']" />
    </a>
</template>

<script>
import "./TextLink.scss";

export default {
    name: 'TextLink',

    props: {
        text: {
            type: String,
            required: true
        },

        link: {
            type: String,
            required: true
        }
    },
}
</script>