<template>
  <PageComponent>
    <SectionComponent id="about" class="nav-section">
      <template v-slot:content>
        <p v-for="(paragraph, index) in aboutParagraphs" :key="index" v-html="paragraph"></p>
      </template>
    </SectionComponent>

    <SectionComponent id="experience" class="nav-section" title="Experience">
      <template v-slot:title-function>
        <TextLink  text="View full CV" link="resume.pdf" />
      </template>
      <template v-slot:content>
        <TimelineComponent :events="careerExperienceEvents" />
      </template>
    </SectionComponent>

    <SectionComponent id="education" class="nav-section" title="Education">
      <template v-slot:content>
        <TimelineComponent :events="careerEducationEvents" />
      </template>
    </SectionComponent>
  </PageComponent>
</template>

<script>
import "./HomePage.scss";
import PageComponent from "@/components/pages/PageComponent.vue";
import SectionComponent from "@/components/section/SectionComponent.vue";
import TextLink from "@/components/text-link/TextLink.vue";
import TimelineComponent from "@/components/timeline/TimelineComponent.vue";

export default {
  name: 'HomePage',

  components: {
    PageComponent,
    SectionComponent,
    TextLink,
    TimelineComponent
  },

  mounted() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  },

  data() {
    return {
      aboutParagraphs: [
        "I'm a full-stack software engineer with a Master's degree in Computer Science and expertise in backend engineering and API design. Having developed for various high-impact companies, ranging from <a href='https://www.systematic.com/' target='_blank'>a NATO partner</a> to <a href='https://www.microsoft.com/' target='_blank'>a major big tech corporation</a>, I've gained invaluable experience in software engineering, leadership and stakeholder management. My passion truly lies in building and delivering impactful solutions that empower individuals and organizations, and I do so with a cup-half-full attitude!",
        "Outside of building cool projects in my work and private time, I also enjoy following sports and esports, playing all sorts of games, hiking, and planning my next adventures."
      ],
      careerExperienceEvents: [
        {
          primaryTitle: "Senior Software Developer (Scrum Master)",
          primaryDescription: "Developing the C4ISR solution <b>SitaWare Headquarters</b> as part of the application security team. The team deals with modernizing the security layer by migrating to an OAuth 2.0-, OIDC- and JWT-compliant solution, utilizing Keycloak for identity- and access management. Our responsibilities also includes extending the cluster capabilities of the solution, utilizing Kubernetes, Istio, Helm and Docker to establish a robust, secure and performant cluster environment.",
          secondaryTitle: "SitaWare Headquarters",
          secondaryDescription: "<b>SitaWare Headquarters</b> provides advanced command-and-control (C2) and battle management capabilities, including the critical interoperability that enables nations to exchange battlespace information with coalition partners. It delivers real-time situational awareness through an integrated platform that combines live data, mapping, and communication tools. Customers include NATO, U.S. Army, and German Army.",
          institution: "Systematic",
          date: "Oct 2023 - now",
          logo_path: "systematic.png",
          stack: [
            "Java", "Scala", "C#", "Angular", "Docker", "Kubernetes", "Keycloak", "TeamCity"
          ]
        },
        {
          primaryTitle: "Software Engineer Student Worker",
          primaryDescription: "Developed the acclaimed ERP solution <b>Dynamics 365 Business Central</b> as part of the Application Foundation team. The team was responsible for the horizontal domain-logic, such as e-mail communication, data import and export, permissions, file-handling, and more. I contributed to the team with the highest number of bug-fixes while developing partner-requested features and onboarding, developing and supervising an intern.",
          secondaryTitle: "Dynamics 365 Business Central",
          secondaryDescription: "<b>Dynamics 365 Business Central</b> is an award-winning, all-in-one business management solution designed for small and medium-sized enterprises. It integrates financials, sales, service, and operations into a unified platform, offering real-time insights and streamlined processes. Business Central helps businesses improve efficiency, manage finances, and drive growth. Its flexibility and scalability make it a powerful tool for optimizing operations and making data-driven decisions.",
          institution: "Microsoft",
          date: "Apr 2022 - Aug 2023",
          logo_path: "microsoft.png",
          stack: [
            "AL", "SQL"
          ]
        },
        {
          primaryTitle: "Software Developer",
          primaryDescription: "Consultant for a number of prominet clients such as Danske Bank, Telia, Chr. Hansen, and SKAT. I focused mainly on backend development in various programming languages, spanning a wide range of tech stacks from small-scale start-up solutions to large-scale, latency-efficient environments. I thrived in tackling steep learning curves and jumping straight into dynamic environments - this enabled me to take control of projects behind on deadlines and deliver high-quality solutions.",
          secondaryTitle: "Projects As Consultant",
          secondaryDescription: "<ul><li><b>Danske Bank</b>: Low-latency eFX pricing engine for calculating and communicating exchange rates.</li><li><b>Telia (Merkle)</b>: Self-service with new design and solution componentization.</li><li><b>Silvi.ai</b>: AI-powered solution for automating meta-analysis within the field of medicine.</li><li><b>SKAT (DXC)</b>: Intercountry VAT communication system with ability to track VAT in call-off-stock arrangements.</li></ul>",
          institution: "IT Minds",
          date: "Feb 2020 - Mar 2022",
          logo_path: "itminds.png",
          stack: [
            "Java", "Scala", "Python", "Vue.js", "SQL", "Docker", "RabbitMQ", "Kafka"
          ]
        },
        {
          primaryTitle: "IT Student",
          primaryDescription: "Developed a standalone database restoration tool for internal use. The solution utilized WinForms framework to provide a UI for developers when restoring databases from stored .bak files.",
          institution: "Danske Bank",
          date: "May 2019 - Jul 2019",
          logo_path: "danskebank.png",
          stack: [
            "C#", "SQL"
          ]
        },
        {
          primaryTitle: "Conscript (Conscript Squad Leader)",
          primaryDescription: "Voluntary conscript with the assigned role of squad leader in charge of managing and leading a squad of 11 conscripts. I focused on leading and motivating the team while facilitating communication between the team and leadership.",
          institution: "Danish Defence",
          date: "Aug 2014 - Nov 2014",
          logo_path: "forsvaret.png"
        }
      ],
      careerEducationEvents: [
        {
          primaryTitle: "MSc in Computer Science",
          primaryDescription: "Expanded my knowledge in algorithms, advanced programming, and IT security. Studying on the software engineering track, I delved into intriguing areas such as blockchain technology, software architecture. Additionally, I researched machine learning applications and implications, and acquired valuable skills in project management, innovation, and entrepreneurship. I wrote my thesis on implementing a blockchain with native support for DCR Graph smart contracts, which received the maximum grade and was followed by a Ph. D. offer.",
          institution: "University of Copenhagen",
          date: "Sep 2021 - Jun 2023",
          logo_path: "ucph.png"
        },
        {
          primaryTitle: "SCRUM Course Certificate",
          primaryDescription: "Learned about Scrum and team management in agile environments. Throughout the duration of the course, I actively participated in various team-based exercises and tackled diverse case studies, improving my skills in effectively organizing and guiding teams towards accomplishing shared objectives.",
          institution: "Systematic",
          date: "May 2017",
          logo_path: "systematic.png"
        },
        {
          primaryTitle: "BSc in Computer Science",
          primaryDescription: "Gained extensive knowledge of programming and advanced computer science theory. I wrote my thesis on analyzing the efficacy of the DoS attack Slowloris and its capabilities in various environments, and how Apache HTTP Server as a webserver implements security techniques to mitigate such attacks.",
          institution: "University of Copenhagen",
          date: "Sep 2016 - Feb 2020",
          logo_path: "ucph.png"
        }
      ]
    }
  },
}
</script>