<template>
  <div class="timeline" ref="timeline">
    <div class="entry" v-for="(event, idx) in events" :key="idx">

      <div class="marker">
        <img :src="require(`@/assets/logos/${event.logo_path}`)" alt="">
      </div>

      <div class="card-container" :style="event.secondaryDescription ? 'cursor: pointer;' : ''" @click="event.secondaryDescription ? switchCard(idx) : ''">
        <div class="card" :class="{'back': switchedCards.includes(idx)}">
          <div class="card-content">
            <div class="card-top">
              <div class="card-details">
                <span class="date">{{ event.date }}</span>
                <span class="institution-title">{{ event.primaryTitle }}</span>
                <span class="institution-name">{{ event.institution }}</span>
              </div>
            </div>
            <p class="card-description" v-html="event.primaryDescription"></p>
          </div>
          <div class="stack">
            <StackItemComponent v-for="(stackitem, idx) in event.stack" :key="idx" :title="stackitem" />
          </div>
        </div>

        <div v-if="event.secondaryDescription" class="card" :class="{'back': !switchedCards.includes(idx)}">
          <div class="card-content">
            <div class="card-top">
              <div class="card-details">
                <span class="date">{{ event.date }}</span>
                <span class="institution-title">{{ event.secondaryTitle }}</span>
                <span class="institution-name">{{ event.institution }}</span>
              </div>
            </div>
            <p class="card-description" v-html="event.secondaryDescription"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./TimelineComponent.scss";
import StackItemComponent from "@/components/stack-item/StackItemComponent.vue";

export default {
  name: 'TimelineComponent',

  components: {
    StackItemComponent
  },
  
  data() {
    return {
      switchedCards: [],
      maxHeight: 0
    }
  },

  props: {
    events: {
      type: Array,
      required: true
    } 
  },

  mounted() {
    window.addEventListener('resize', this.setMaxCardHeights());

    const timeline = this.$refs.timeline;
    if (timeline) {
      const entryWrappers = timeline.getElementsByClassName('card-container');
      for (let i = 0; i < entryWrappers.length; i++) {
        setTimeout(() => {
          entryWrappers[i].style.opacity = 1;
        }, i * 250);
      }
    }
  },

  methods: {
    setMaxCardHeights() {
      const cardContainers = this.$refs.timeline.getElementsByClassName('card-container');

      for (let i = 0; i < cardContainers.length; i++) {
        const cardContainer = cardContainers[i];
        const cardElements = cardContainers[i].getElementsByClassName('card');
        let maxHeight = 0;
        for (let j = 0; j < cardElements.length; j++) {
          const cardHeight = cardElements[j].offsetHeight;
          if (cardHeight > maxHeight) {
            maxHeight = cardHeight;
          }
        }

        this.maxHeight = maxHeight;
        cardContainer.style.height = `${this.maxHeight}px`;
      }
    },

    switchCard(idx) {
      if (this.switchedCards.includes(idx)) {
        this.switchedCards = this.switchedCards.filter(switchedIdx => switchedIdx != idx);
      } else {
        this.switchedCards.push(idx);
      }
    }
  }
}
</script>