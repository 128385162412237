<template>
  <section class="section-component">
    <div v-if="title" class="title-container">
      <span class="title">{{ title }}</span>
      <slot name="title-function"></slot>
    </div>

    <div class="content">
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script>
import "./SectionComponent.scss";

export default {
  name: 'SectionComponent',

  props: {
    title: {
      type: String,
    },
  },
}
</script>