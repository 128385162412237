<template>
  <div class="page-component">
    <slot></slot>

    <span class="scroll-top-shortcut" @click="scrollToTop()">
      Back to the top
      <font-awesome-icon class="shortcut-icon" :icon="['fa', 'angle-up']" />
    </span>

    <div class="seperator"></div>

    <div class="contact-links">
        <a class="link" href="https://www.linkedin.com/in/casper-stybert/" target="_blank">
            <font-awesome-icon class="link-icon" :icon="['fab', 'linkedin']" />
        </a>
        <a class="link" href="mailto:cstybert@gmail.com" target="_blank">
            <font-awesome-icon class="link-icon" :icon="['fa', 'envelope']" />
        </a>
        <a class="link" href="https://github.com/cstybert" target="_blank">
            <font-awesome-icon class="link-icon" :icon="['fab', 'github']" />
        </a>
    </div>
  </div>
</template>

<script>
import "./PageComponent.scss";

export default {
  name: 'PageComponent',

  methods: {
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>